<template>
  <div>
    <div v-if="this.$route.name === 'Company'">
      <Company />
    </div>
    <router-view />
  </div>
</template>
<script>
import Company from "@/views/adminApp/companies/Company.vue";

export default {
  name: 'CompanyParent',
  components: {
    Company
  }
}
</script>
